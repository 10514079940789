<template>
  <div>
    <h3>Blog Category</h3>
    <v-btn
      text
      @click="showDialog"
      outlined
      class="btn btn-lg btn-add-main"
      style="background:transparent;"
    >
      <i class="fas fa-plus-circle"></i> Add New Category
    </v-btn>
    <v-btn
      text
      :to="{ name: 'manage-blogs', params: { domainname: siteUrl }}"
      outlined
      class="btn btn-lg btn-secondary-main"
      style="background:transparent;"
    >
      <i class="fas fa-arrow-left"></i> Back
    </v-btn>
    <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{siteUrl}}
    </span>
    <v-app>
      <div class="row mt-2">
        <div class="col-xl-6">
          <v-card>
            <v-card-text>
              <div class="row" v-if="blogCategorys && blogCategorys.length<1">
                <div class="col-md-12 text-center">
                  <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                  <h5 class="mt-10">Looks like you have not added any data here. Trying adding some data.</h5>
                </div>
              </div>
              <v-treeview v-else dense :items="blogCategorys" return-object open-all item-text="label">
                <template v-slot:label="{ item }">{{item.label}}</template>
                <template v-slot:prepend="{ item }">
                  <v-icon v-if="item.is_active" x-small color="green darken-2">fa fa-check</v-icon>
                  <v-icon v-else large color="red darken-2">fa fa-ban</v-icon>
                </template>
                <template v-slot:append="{ item }">
                  <a href class="mx-2" @click.prevent="editCategory(item)">
                    <v-icon x-small color=" darken-2">kt-nav__link-icon flaticon-edit</v-icon>
                  </a>
                  <a href @click.prevent="delele(item.id)">
                    <v-icon x-small color="red darken-2">kt-nav__link-icon flaticon-delete</v-icon>
                  </a>
                </template>
              </v-treeview>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-6" v-if="blogCategoryDialog">
          <v-card>
            <v-card-title>
              <span class="headline">{{isEdit? "Edit" : "Add"}} Blog Category</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <div class="form-block">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Title"
                        v-model="blogCategory.title"
                        required
                        outlined
                        densed
                      ></v-text-field>
                      <p
                        class="text-danger font-weight-bold"
                        v-if="$v.blogCategory.title.$error"
                      >Title is required</p>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-select
                        v-model="blogCategory.layout"
                        :items="layoutList"
                        item-text="name"
                        item-value="value"
                        label="Select Layout"
                        outlined
                        densed
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="12" v-if="!blogCategory.is_parent">
                      <cascader
                        style="width:100%;"
                        size="large"
                        :options="blogCategorys"
                        v-model="selected"
                        :fieldNames="{label: 'title', value: 'id', children: 'children'}"
                        placeholder="Select parent category"
                        change-on-select
                      />
                    </v-col>
                    <!-- <pre>{{blogCategorys}}</pre> -->
                    <!-- <v-col cols="12" md="12">
                      <v-select
                        v-model="blogCategory.parent_id"
                        :items="blogCategorys"
                        item-text="title"
                        item-value="id"
                        label="Select Parent Category"
                        outlined
                        densed
                      ></v-select>
                    </v-col>-->
                    <!-- <pre>{{selected}}</pre> -->
                    <v-col cols="12" md="12">
                      <v-switch v-model="blogCategory.is_parent" label="Top Level"></v-switch>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-switch v-model="blogCategory.is_active" label="Visibility"></v-switch>
                    </v-col>
                  </v-row>
                  <v-row></v-row>
                </div>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
<!--              <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>-->
              <v-btn
                color="white--text v-btn theme--light elevation-2 v-size--large primary"
                class="btn-save-popup"
                :loading="isBusy"
                text
                @click.prevent="saveOrUpdate"
              >Save</v-btn>
            </v-card-actions>
          </v-card>
          <!--                    <KTPortlet v-bind:title="''">-->
          <!--                        <template v-slot:body>-->
          <!--                            <b-table-->
          <!--                                hover-->
          <!--                                :items="blogCategorys"-->
          <!--                                id="my-table"-->
          <!--                                :busy.sync="isBusy"-->
          <!--                                :fields="fields"-->
          <!--                                :current-page="currentPage"-->
          <!--                                :per-page="perPage"-->
          <!--                            >-->
          <!--                                <template v-slot:table-busy>-->
          <!--                                    <div class="text-center text-info my-2">-->
          <!--                                        <b-spinner class="align-middle"></b-spinner>-->
          <!--                                        <strong>Loading Blog Categories...</strong>-->
          <!--                                    </div>-->
          <!--                                </template>-->
          <!--                                &lt;!&ndash; A virtual composite column &ndash;&gt;-->
          <!--                                <template v-slot:cell(is_parent)="data">-->
          <!--                                    <i-->
          <!--                                        v-if="data.item.is_parent"-->
          <!--                                        class="kt-nav__link-icon flaticon2-check-mark text-success"-->
          <!--                                    ></i>-->
          <!--                                    <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>-->
          <!--                                </template>-->
          <!--                                <template v-slot:cell(updated_at)="data">-->
          <!--                                    <span>{{moment(data.item.updated_at ,'YYYY-MM-DD').fromNow()}}</span>-->
          <!--                                </template>-->
          <!--                                <template v-slot:cell(is_active)="data">-->
          <!--                                    <i-->
          <!--                                        v-if="data.item.is_active"-->
          <!--                                        class="kt-nav__link-icon flaticon2-check-mark text-success"-->
          <!--                                    ></i>-->
          <!--                                    <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>-->
          <!--                                </template>-->
          <!--                                <template v-slot:cell(action)="data">-->
          <!--                                    <div class="kt-widget__toolbar">-->
          <!--                                        <a-->
          <!--                                            href="#"-->
          <!--                                            class="btn btn-clean btn-sm btn-icon btn-icon-md"-->
          <!--                                            data-toggle="dropdown"-->
          <!--                                        >-->
          <!--                                            <i class="flaticon-more-1"></i>-->
          <!--                                        </a>-->
          <!--                                        <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">-->
          <!--                                            <ul class="kt-nav">-->
          <!--                                                <li class="kt-nav__item">-->
          <!--                                                    <a href="#" @click="editCategory(data.item)" class="kt-nav__link"-->
          <!--                                                       to>-->
          <!--                                                        <i class="kt-nav__link-icon flaticon-edit"></i>-->
          <!--                                                        <span class="kt-nav__link-text">Edit</span>-->
          <!--                                                    </a>-->
          <!--                                                </li>-->

          <!--                                                <li class="kt-nav__item">-->
          <!--                                                    <a href="#" class="kt-nav__link" @click="delele(data.item.id)">-->
          <!--                                                        <i class="kt-nav__link-icon flaticon-delete kt-font-danger"></i>-->
          <!--                                                        <span class="kt-nav__link-text">Delete</span>-->
          <!--                                                    </a>-->
          <!--                                                </li>-->
          <!--                                            </ul>-->
          <!--                                        </div>-->
          <!--                                    </div>-->
          <!--                                </template>-->
          <!--                            </b-table>-->
          <!--                            <b-pagination-->
          <!--                                v-model="currentPage"-->
          <!--                                :total-rows="rows"-->
          <!--                                :per-page="perPage"-->
          <!--                                aria-controls="my-table"-->
          <!--                            ></b-pagination>-->
          <!--                        </template>-->
          <!--                    </KTPortlet>-->
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import { required } from "vuelidate/lib/validators";
import Website from "@/services/Websites/WebsiteService";
import Cascader from "ant-design-vue/lib/cascader";
import "ant-design-vue/dist/antd.css";

import WebsiteBlogCategory from "@/services/Websites/WebsiteBlogCategoryService";

const WebsiteService = new Website();
const WebsiteBlogCategoryService = new WebsiteBlogCategory();
export default {
  name: "website-blogs-categories",
  components: {
    KTPortlet,
    Cascader
  },
  validations: {
    blogCategory: {
      title: { required }
    }
  },
  data() {
    return {
      isBusy: false,
      isEdit: false,
      blogCategoryDialog: true,
      sortBy: "title",
      sortDesc: false,
      date: new Date().toISOString().substr(0, 7),
      layoutList: [
        {
          name: "Standard",
          value: "standard"
        },
        {
          name: "FAQ",
          value: "faq"
        }
      ],
      fields: [
        { key: "title", sortable: true },
        { key: "is_active", label: "Published", sortable: true },
        { key: "action" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      selected: [],
      blogCategory: {
        id: null,
        title: "",
        slug: "",
        layout: null,
        is_parent: 0,
        is_active: 1,
        parent_id: null
      },
      blogCategorys: []
    };
  },
  methods: {
    showDialog() {
      this.blogCategoryDialog = true;
    },
    closeDialog() {
      this.blogCategoryDialog = false;
      this.resetBlogCategory();
    },
    delele(id) {
        this.$confirm(
            {
                message: `Are you sure you want to permanently remove this item? `,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        WebsiteBlogCategoryService.delete(this.siteUrl, id).then(res => {
                            this.$snotify.success("Blog category deleted");
                            this.getBlogCategory();
                        });
                    }
                }
            }
        )

    },
    editCategory(category) {
      this.isEdit = true;
      this.showDialog();
      this.blogCategory = category;
      this.selected.push(this.blogCategory.parent_id);
    },
    saveOrUpdate() {
      this.$v.$touch();
      if (!this.$v.blogCategory.$error) {
        if (this.selected.length > 0)
          this.blogCategory.parent_id = this.selected[this.selected.length - 1];
        this.isEdit ? this.updateBlogCategory() : this.saveblogCategory();
      }
    },
    updateBlogCategory() {
      this.isBusy = true;
      WebsiteBlogCategoryService.update(
        this.siteUrl,
        this.blogCategory.id,
        this.blogCategory
      )
        .then(res => {
          this.isBusy = false;
          this.$snotify.success("Page added");

          this.getBlogCategory();
          this.closeDialog();
        })
        .catch(error => {})
        .finally(() => (this.isBusy = false));
    },
    saveblogCategory() {
      this.isBusy = true;
      WebsiteBlogCategoryService.create(this.siteUrl, this.blogCategory)
        .then(res => {
          this.isBusy = false;
          this.$snotify.success("Page added");
          this.getBlogCategory();
          this.closeDialog();
        })
        .catch(error => {})
        .finally(() => (this.isBusy = false));
    },

    resetBlogCategory() {
      this.blogCategory = {
        title: "",
        slug: "",
        is_parent: 1,
        is_active: 1,
        parent_id: null
      };
      this.selected = [];
      this.isEdit = false;
      this.isEdit = false;
    },
    getHumanDate: function(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    getBlogCategory() {
      WebsiteBlogCategoryService.paginate(this.siteUrl).then(res => {
        this.blogCategorys = res.data.data;
        // this.pages = res.data.data;
      });
    }
  },
  computed: {
    rows() {
      return this.blogCategorys.length;
    },
    siteUrl() {
      return this.$route.params.domainname;
    }
  },
  mounted() {
    this.getBlogCategory();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Manage Blogs Category", route: "/websites" }
    ]);
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};
</script>
<style >
.v-icon.v-icon.v-icon--link {
    font-size: 35px !important;
}

</style>
